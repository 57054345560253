import { CONFIG } from '../config';
import React, { useEffect, useState } from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Moment from 'moment';

import USDTTokenIcon from '../assets/img/usdt-token.png';

// hooks
import { useWithdrawal } from '../hooks/useWithdrawal';

const Invest = () => {

	const blockchain = useSelector((state) => state.blockchain);

	const [fetchWalletData, setfetchWalletData] = useState(blockchain.account);
	// const [fetchWalletData, setfetchWalletData] = useState("0x8e52f8a60ff89c2827ef0a5834d73cab08768df0");
	

	const [userData, setUserData] = useState(null);
	const [userTotalWithdraw, setUserTotalWithdraw] = useState(0);

	const [expandStakeDetails, setExpandStakeDetails] = useState(false);
	const [expandWithdrawDetails, setExpandWithdrawDetails] = useState(false);

	const [aimmPrice, setAimmPrice] = useState(0);
	const fetchAimmFromDex = async () => {
		const response = await fetch(CONFIG.AIMM_CONTRACT_PAIR);
		const json = await response.json();
		if (response.ok) {
			setAimmPrice(json.pair.priceUsd);
		}
	}

	const [usdtBalance, setUSDTBalance] = useState(0);
	const getUserUSDTBalance = () => {
	  blockchain.helperContract.methods.getTokenBalance(fetchWalletData, "0x55d398326f99059fF775485246999027B3197955").call()
	  .then((results) => {
		console.log("USDT Balance:", results);
		setUSDTBalance(results);
	  });
	};

	const [allowance, setAllowance] = useState(0);
	const checkTokenAllowance = () => {
	  blockchain.usdtContract.methods.allowance(fetchWalletData, "0xd2E1236456B07B0baD066344659D161a171CCAE2").call()
	  .then((results) => {
		console.log("Token Allowance:", results);
		setAllowance(results);
	  });
	};

	const [activeDeposit, setActiveDeposit] = useState(0);
	const getUserData = async () => {
		const response = await fetch('https://api.tradevisionai.com/user/'+fetchWalletData);
		const json = await response.json();
		if (response.ok) {
			if (json.length === 1) {
				// Expect only 1 result
				setUserData(json[0]);
				if (json[0].last_withdraw !== undefined || json[0].last_withdraw != null) {
					setUserTotalWithdraw(json[0].total_withdraw);
				}
				if (json[0].expired_deposit !== undefined || json[0].expired_deposit != null) {
					setActiveDeposit(json[0].total_deposit-json[0].expired_deposit);
					console.log("Active Deposit", json[0].total_deposit-json[0].expired_deposit);
				} else {
					setActiveDeposit(json[0].total_deposit);
				}
				console.log("userData:", json[0]);
			} else {
				console.log("Invalid user data: More than 1 result.")
			}
		}
	}

	const [referralComm, setReferralComm] = useState(0);
	const calculateReferralComm = async () => {
		const response = await fetch('https://api.tradevisionai.com/user/downline-commission/'+fetchWalletData);
		const json = await response.json();
		if (response.ok) {
			console.log(json);
			setReferralComm(json.totalCommission);
		}
	}

	const rankBonusPercentages = [
		0,
		0.05,
		0.1,
		0.2,
		0.3,
		0.4
	];

	const [firstDepositTime, setFirstDepositTime] = useState(0);
	const [v3Deadline, setV3Deadline] = useState(0);
	const [userDepositData, setUserDepositData] = useState(null);
	const getUserDepositData = async () => {
		const response = await fetch('https://api.tradevisionai.com/deposit/wallet/'+fetchWalletData);
		const json = await response.json();
		if (response.ok) {
			console.log("User Deposits:", json);
			setUserDepositData(json);
			if (json.length > 0) {
				const returnData = await fetch('https://api.tradevisionai.com/user/'+fetchWalletData);
				const data = await returnData.json();
				if (returnData.ok) {
					if (data.length === 1) {
						if (data[0].last_withdraw !== undefined || data[0].last_withdraw != null) {
							console.log("User data exists");
							setFirstDepositTime(Date.now()-(Moment(data[0].last_withdraw).unix()*1000));
							if (1725206400000-(Moment(data[0].last_withdraw).unix()*1000) > 0) {
								setV3Deadline(1725206400000-(Moment(data[0].last_withdraw).unix()*1000));
							} else {
								setV3Deadline(0);
							}
						} else {
							console.log("User data not exists");
							setFirstDepositTime(Date.now()-(Moment(json[json.length-1].createdAt).unix()*1000));
							if (1725206400000-(Moment(data[0].last_withdraw).unix()*1000) > 0) {
								setV3Deadline(1725206400000-(Moment(json[json.length-1].createdAt).unix()*1000));
							} else {
								setV3Deadline(0);
							}
						}
					} else {
						console.log("Invalid user data: More than 1 result.")
					}

				}
			}
		}
	}

	const [approving, setApproving] = useState(false);
	const approveToken = () => {
	  setApproving(true);
	  blockchain.usdtContract.methods
	  .approve("0xd2E1236456B07B0baD066344659D161a171CCAE2", blockchain.web3.utils.toWei((100000).toString(), "ether"))
	  .send({
		gasLimit: 80000,
		maxPriorityFeePerGas: 3000000000,
		maxFeePerGas: 3000000000,
		from: blockchain.account,
		value: 0,
	  })
	  // Transaction fail
	  .once("error", (err) => {
		setApproving(false);
	  })
	  // Transaction success
	  .then((receipt) => {
		setTimeout(() => checkTokenAllowance(), 3000);
		setTimeout(() => setApproving(false), 5000);
	  });
	};

	const [amount, setAmount] = useState(0);
	const [balError, setBalError] = useState('');
	const [invalidAmount, setInvalidAmount] = useState(true);
	const handleAmountInput = (e) => {
		const value = e.target.value;
		e.preventDefault();
		if (isNaN(+value)) {
			setInvalidAmount(true);
			setBalError('必须输入数字！');
		} else if (value < 50) {
			setInvalidAmount(false);
			setBalError('托管数额最低 50 USDT');
		} else if (value > usdtBalance/1000000000000000000) {
			setInvalidAmount(false);
			setBalError('钱包 USDT 数额不足！');
		} else {
			setInvalidAmount(false);
			setBalError('');
		}
		setAmount(e.target.value);
	};

	const [investing, setInvesting] = useState(false);
	const [investSuccess, setInvestSuccess] = useState(false);
	const investUSDT = () => {
	  setInvesting(true);
	  blockchain.tradeVisionAIDepositV2.methods
	  .invest("0x55d398326f99059fF775485246999027B3197955", amount, false, "0xa6FF14636C31fa4799d742185f723522C8F2Ab48")
	  .send({
		gasLimit: 80000,
		maxPriorityFeePerGas: 3000000000,
		maxFeePerGas: 3000000000,
		from: blockchain.account,
		value: 0,
	  })
	  // Transaction fail
	  .once("error", (err) => {
		setInvesting(false);
	  })
	  // Transaction success
	  .then((receipt) => {
		// Update total deposit
		fetch('https://api.tradevisionai.com/user/'+userData._id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                total_deposit: Number(userData.total_deposit)+Number(amount)
            })
        });

		fetch('https://api.tradevisionai.com/deposit/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
				referrer: userData.referrer,
				user_id: userData._id,
				wallet: blockchain.account,
				deposit_token: "USDT",
				deposit_amount: Number(amount)
            })
        });
		setInvestSuccess(true);
		setTimeout(() => getUserData(), 3000);
		setTimeout(() => getUserDepositData(), 3000);
		setTimeout(() => checkTokenAllowance(), 3000);
		setTimeout(() => setInvesting(false), 5000);
	  });
	};

	const { submitWithdraw, isWithdrawing } = useWithdrawal();

	const [withdrawAmount, setWithdrawAmount] = useState(0);
	const [withdrawAmountError, setWithdrawAmountError] = useState('');
	const [invalidWithdrawAmount, setInvalidWithdrawAmount] = useState(true);
	const handleWithdrawAmount = (e) => {
		const value = e.target.value;
		e.preventDefault();
		if (isNaN(+value)) {
			setInvalidWithdrawAmount(true);
			setWithdrawAmountError('必须输入数字！');
		} else if (value < 10 || value%10 != 0) {
			setInvalidWithdrawAmount(false);
			setWithdrawAmountError('最低提现10USDT, 10的倍数。');
		} else if (value > usdtBalance/1000000000000000000) {
			setInvalidWithdrawAmount(false);
			setWithdrawAmountError('钱包 USDT 数额不足！');
		} else {
			setInvalidWithdrawAmount(false);
			setWithdrawAmountError('');
		}
		setAmount(e.target.value);
	};

	const [buttonDisabled, setButtonDisabled] = useState(false);
	const userWithdraw = async (_amount, _fee) => {
		await submitWithdraw(userData._id, blockchain.account, _amount, _fee);
		setTimeout(() => getUserData(), 2000);
	};

	const [userWithdrawalData, setUserWithdrawalData] = useState(null);
	const getUserWithdrawalData = async () => {
		const response = await fetch('https://api.tradevisionai.com/withdrawal/wallet/'+fetchWalletData);
		const json = await response.json();
		if (response.ok) {
			setUserWithdrawalData(json);
		}
	}

	const [loaderDelay, setLoaderDelay] = useState(true);
	useEffect(() => {
		fetchAimmFromDex();
		calculateReferralComm();
		getUserData();
		// getUserDirectReferrals();
		getUserDepositData();
		getUserWithdrawalData();
		getUserUSDTBalance();
		checkTokenAllowance();
		setTimeout(() => setLoaderDelay(false), 5000);
	}, []);


	return (
		<>
		<div className="main-content">
			{/* Withdrawal Modal */}
			<div class="modal fade" id="withdrawModal" tabindex="-1" data-backdrop="static" data-keyboard="false" aria-labelledby="withdrawModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content invest-modal py-3">
						<div class="modal-body text-center px-3">
							<>
								{!buttonDisabled ? (
									<>
										<h5 className="blue mb-0">可提现收益:</h5>
											<>
												{userData && ((userData.total_deposit*3) - (userData.total_withdraw || 0)) > (userData.withdrawable || 0)+(userData.stakeEarning || 0)+(userData.teamBonus || 0)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0) ? (
													<p className="token-balance text-white" style={{fontSize:"18px",fontWeight:"700"}}>
														<span className="profit-green">
															{userData && parseFloat((userData.withdrawable || 0)+(userData.stakeEarning || 0)+(userData.teamBonus || 0)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0)).toFixed(2)}
														</span> USDT
													</p>
												):(
													<p className="token-balance text-white" style={{fontSize:"18px",fontWeight:"700"}}>
														<span className="profit-green">
															{userData && parseFloat((userData.total_deposit*3) - (userData.total_withdraw || 0)).toFixed(2)}
														</span> USDT
													</p>
												)}
												{/* <p className="text-gray mt-3 mb-2" style={{fontSize:"14px"}}>请输入提现数额：</p>
												<input
													type="text"
													step="1"
													placeholder='提现数额'
													autoComplete='off'
													name='withdrawAmount'
													onChange={handleWithdrawAmount}
												/>
												<p className="error mt-2" style={{fontSize:"16px", fontWeight:"700"}}>{withdrawAmountError}</p> */}
												{userData && parseFloat((userData.withdrawable || 0)+(userData.stakeEarning || 0)+(userData.teamBonus || 0)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0)).toFixed(2) >= 10 ? (
													<>
														{userData && ((userData.total_deposit*3) - (userData.total_withdraw || 0)) > (userData.withdrawable || 0)+(userData.stakeEarning || 0)+(userData.teamBonus || 0)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0) ? (
															<>
																<p className="text-white mt-3 mb-0" style={{fontSize:"16px"}}><span className="blue">提现手续费</span><br></br>- {userData && parseFloat(((userData.withdrawable || 0)+(userData.stakeEarning || 0)+(userData.teamBonus || 0)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0))*0.3/aimmPrice).toFixed(2)} AIMM</p>
																<p className="text-gray mt-2 mb-0" style={{fontSize:"14px"}}>≈ ${userData && parseFloat(((userData.withdrawable || 0)+(userData.stakeEarning || 0)+(userData.teamBonus || 0)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0))*0.3).toFixed(2)} USDT</p>
																<p className="text-white mt-3 mb-0" style={{fontSize:"14px"}}><span className="blue">可用: </span>{userData && userData.aimm_balance > 0 ? parseFloat(userData.aimm_balance).toFixed(2) : parseFloat(0).toFixed(2)} AIMM</p>
																{userData && userData.aimm_balance == null || userData.aimm_balance < parseFloat(((userData.withdrawable || 0)+(userData.stakeEarning || 0)+(userData.teamBonus || 0)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0))*0.3/aimmPrice).toFixed(2) ? (
																	<>
																		<p className="error mt-2 mb-0" style={{fontSize:"16px"}}>
																			AIMM 数额不足，请充值。
																		</p>
																		<NavLink to="/wallet">
																			<button className="btn btn-primary mx-2 mt-3" data-dismiss="modal" onClick={(e) => {
																			}}>
																				<p className="text-white mb-0" style={{fontSize:"16px"}}>充值 AIMM</p>
																			</button>
																		</NavLink>
																	</>
																):(
																	<>
																		{userData && ((userData.total_deposit*3) - (userData.total_withdraw || 0)) > (userData.withdrawable || 0)+(userData.stakeEarning || 0)+(userData.teamBonus || 0)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0) ? (
																			<button disabled={buttonDisabled} className="btn btn-primary mx-2 mt-3" onClick={(e) => {
																				setButtonDisabled(true);
																				setTimeout(() => 
																					userWithdraw(
																						parseFloat((userData.withdrawable || 0)+(userData.stakeEarning || 0)+(userData.teamBonus || 0)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0)).toFixed(2),
																						parseFloat(((userData.withdrawable || 0)+(userData.stakeEarning || 0)+(userData.teamBonus || 0)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0))*0.3/aimmPrice).toFixed(2)
																					), 1000);
																			}}>
																				<p className="text-white mb-0" style={{fontSize:"16px"}}>确认</p>
																			</button>
																		):(
																			<button disabled={buttonDisabled} className="btn btn-primary mx-2 mt-3" onClick={(e) => {
																				setButtonDisabled(true);
																				setTimeout(() => 
																					userWithdraw(
																						parseFloat((userData.total_deposit*3) - (userData.total_withdraw || 0)).toFixed(2),
																						parseFloat(((userData.total_deposit*3) - (userData.total_withdraw || 0))*0.3/aimmPrice).toFixed(2)
																					), 1000);
																			}}>
																				<p className="text-white mb-0" style={{fontSize:"16px"}}>确认</p>
																			</button>
																		)}
																		<button className="btn btn-danger mx-2 mt-3" data-dismiss="modal" onClick={(e) => {
																		}}>
																			<p className="text-white mb-0" style={{fontSize:"16px"}}>取消</p>
																		</button>
																		{/* <p className="yellow mt-3" style={{fontSize:"14px"}}>提现功能正在升级中<br></br>升级后用户可以自行输入提现数额</p> */}
																		{/* <button className="btn btn-danger mx-2 mt-3" data-dismiss="modal" onClick={(e) => {
																		}}>
																			<p className="text-white mb-0" style={{fontSize:"16px"}}>关闭</p>
																		</button> */}
																	</>
																)}
															</>
														):(
															<>
																<p className="text-white mt-3 mb-0" style={{fontSize:"16px"}}><span className="blue">提现手续费</span><br></br>- {userData && parseFloat(((userData.total_deposit*3) - (userData.total_withdraw || 0))*0.3/aimmPrice).toFixed(2)} AIMM</p>
																<p className="text-gray mt-2 mb-0" style={{fontSize:"14px"}}>≈ ${userData && parseFloat(((userData.total_deposit*3) - (userData.total_withdraw || 0))*0.3).toFixed(2)} USDT</p>
																<p className="text-white mt-3 mb-0" style={{fontSize:"14px"}}><span className="blue">可用: </span>{userData && userData.aimm_balance > 0 ? parseFloat(userData.aimm_balance).toFixed(2) : parseFloat(0).toFixed(2)} AIMM</p>
																{userData && userData.aimm_balance == null || userData.aimm_balance < parseFloat(((userData.total_deposit*3) - (userData.total_withdraw || 0))*0.3/aimmPrice).toFixed(2) ? (
																	<>
																		<p className="error mt-2 mb-0" style={{fontSize:"16px"}}>
																			AIMM 数额不足，请充值。
																		</p>
																		<NavLink to="/wallet">
																			<button className="btn btn-primary mx-2 mt-3" data-dismiss="modal" onClick={(e) => {
																			}}>
																				<p className="text-white mb-0" style={{fontSize:"16px"}}>充值 AIMM</p>
																			</button>
																		</NavLink>
																	</>
																):(
																	<>
																		{userData && ((userData.total_deposit*3) - (userData.total_withdraw || 0)) > (userData.withdrawable || 0)+(userData.stakeEarning || 0)+(userData.teamBonus || 0)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0) ? (
																			<button disabled={buttonDisabled} className="btn btn-primary mx-2 mt-3" onClick={(e) => {
																				setButtonDisabled(true);
																				setTimeout(() => 
																					userWithdraw(
																						parseFloat((userData.withdrawable || 0)+(userData.stakeEarning || 0)+(userData.teamBonus || 0)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0)).toFixed(2),
																						parseFloat(((userData.withdrawable || 0)+(userData.stakeEarning || 0)+(userData.teamBonus || 0)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0))*0.3/aimmPrice).toFixed(2)
																					), 1000);
																			}}>
																				<p className="text-white mb-0" style={{fontSize:"16px"}}>确认</p>
																			</button>
																		):(
																			<button disabled={buttonDisabled} className="btn btn-primary mx-2 mt-3" onClick={(e) => {
																				setButtonDisabled(true);
																				setTimeout(() => 
																					userWithdraw(
																						parseFloat((userData.total_deposit*3) - (userData.total_withdraw || 0)).toFixed(2),
																						parseFloat(((userData.total_deposit*3) - (userData.total_withdraw || 0))*0.3/aimmPrice).toFixed(2)
																					), 1000);
																			}}>
																				<p className="text-white mb-0" style={{fontSize:"16px"}}>确认</p>
																			</button>
																		)}
																		<button className="btn btn-danger mx-2 mt-3" data-dismiss="modal" onClick={(e) => {
																		}}>
																			<p className="text-white mb-0" style={{fontSize:"16px"}}>取消</p>
																		</button>
																		{/* <p className="yellow mt-3" style={{fontSize:"14px"}}>提现功能正在升级中<br></br>升级后用户可以自行输入提现数额</p> */}
																		{/* <button className="btn btn-danger mx-2 mt-3" data-dismiss="modal" onClick={(e) => {
																		}}>
																			<p className="text-white mb-0" style={{fontSize:"16px"}}>关闭</p>
																		</button> */}
																	</>
																)}
															</>
														)}
													</>
												):(
													<>
														<p className="error mt-2 mb-0" style={{fontSize:"16px"}}>
															提现数额不足：最少 10 USDT
														</p>
														<button className="btn btn-danger mx-2 mt-3" data-dismiss="modal" onClick={(e) => {
														}}>
															<p className="text-white mb-0" style={{fontSize:"16px"}}>取消</p>
														</button>
													</>
												)}

											</>
									</>
								):(
									<>
										<p className="text-white mt-2 mb-0" style={{fontSize:"16px"}}>
											提现手续正在处理中，<br></br>请稍后检查钱包到账。
										</p>
										<button className="btn btn-primary mx-2 mt-3" data-dismiss="modal" onClick={(e) => {
											getUserData();
											getUserDepositData();
											getUserWithdrawalData();
											setTimeout(() => setButtonDisabled(false), 1000);
										}}>
											<p className="text-white mb-0" style={{fontSize:"16px"}}>完成</p>
										</button>
									</>
								)}
							</>
						</div>
					</div>
				</div>
			</div>
			
			{/* User Staking Details */}
			<div className="container mt-5">
				<div className="row">
					<div className="col-12">
						<h3 className="text-white"><i className="fa-regular fa-chart-mixed-up-circle-dollar mx-2"></i>托管中心</h3>
					</div>

					{/* Deposit Records */}
					<div className="col-12 mt-2 mt-md-3 px-2">
						<button className="btn w-100 text-left data-box" onClick={(e) => {
							setExpandStakeDetails(!expandStakeDetails);
						}}>
							<div className="row">
								<div className="col-12">
									<h5 className="blue mb-0">我的总托管金额:</h5>
									{loaderDelay ? (
										<p className="text-white mb-0" style={{fontSize:"14px"}}>正在加载中。。。</p>
									):(
										<p className="token-balance text-white mt-1 mb-0">
											<span className="profit-green">{userData && parseFloat(userData.total_deposit).toFixed(2)}</span> USDT
										</p>
									)}
								</div>
							</div>
							{!loaderDelay && expandStakeDetails ? (
								<>
									{userDepositData && userDepositData.length > 0 ? (
										<table className="table borderless mt-3">
											<thead>
												<tr>
													<th scope="col" className="py-2"><p className="blue mb-0" style={{fontSize:"16px"}}>托管记录：</p></th>
												</tr>
											</thead>
											<tbody>
												{userDepositData && userDepositData.map((deposit, index) => (
													<tr key={index}>
														<td className="py-1">
															<p className="text-white mb-0" style={{fontSize:"14px"}}>
																{Moment(deposit.createdAt).format('YYYY/MM/DD')} <span className="profit-green">{parseFloat(deposit.deposit_amount).toFixed(2)} USDT </span><span className="text-gray">{deposit.isActive === false ? "(已出局)" : ""}</span>
															</p>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									):(
										<p className="text-gray mt-3 mb-0">此账户没有托管记录。</p>
									)}
								</>
							):(
								<></>
							)}
						</button>
					</div>

					{/* Earning Summary */}
					<div className="col-12 mt-2 mt-md-3 px-2">
						<div className="data-box">
							<div className="row">
								<div className="col-md-8">
									<h5 className="blue mb-0">我的可提现金额:</h5>
									{loaderDelay ? (
										<p className="text-white mb-0" style={{fontSize:"14px"}}>正在加载中。。。</p>
									):(
										<>
											{userData && ((userData.total_deposit*3) - (userData.total_withdraw || 0)) > (userData.withdrawable || 0)+(userData.stakeEarning || 0)+(userData.teamBonus || 0)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0) ? (
												<p className="token-balance text-white mt-1 mb-0">
													<span className="profit-green">
														{userData && parseFloat((userData.withdrawable || 0)+(userData.stakeEarning || 0)+(userData.teamBonus || 0)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0)).toFixed(2)}
													</span> USDT
												</p>
											):(
												<p className="token-balance text-white mt-1 mb-0">
													<span className="profit-green">
														{userData && parseFloat((userData.total_deposit*3) - (userData.total_withdraw || 0)).toFixed(2)}
													</span> USDT
												</p>
											)}
											{/* <p className="yellow mb-0" style={{fontSize:"14px"}}>数据重整中</p> */}
										</>
									)}
								</div>
								<div className="col-md-4 mt-0 mt-md-2 text-md-right">
									{loaderDelay ? (
										<></>
									):(
										<>
											{userData && userData.total_deposit*3 == userData.total_withdraw ? (
												<></>
											):(
												<button className="btn btn-primary mt-2 mt-md-0" data-toggle="modal" data-target="#withdrawModal" onClick={(e) => {
												}}>
													<p className="text-white mb-0">提现</p>
												</button>
											)}

										</>
									)}
								</div>
							</div>
							<table className="table borderless mt-3">
								<thead>
									<tr>
										<th scope="col" className="py-2">
											<p className="blue mb-0" style={{fontSize:"16px"}}>收益明细：</p>
										</th>
									</tr>
								</thead>
								{loaderDelay ? (
									<tbody>
										<tr>
											<td className="py-2"><p className="text-white mb-0" style={{fontSize:"14px"}}>正在加载中。。。</p></td>
										</tr>
									</tbody>
								):(
									<tbody>
										<tr>
											<td className="py-2">
												{userData && ((userData.total_deposit*3) - (userData.total_withdraw || 0)) > (userData.withdrawable || 0)+(userData.stakeEarning || 0)+(userData.teamBonus || 0)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0) ? (
													<>
														{userData && userData.total_deposit >= 50 ? (
															<>
																<p className="text-white mb-0" style={{fontSize:"14px"}}>系统升级前总收益: <span className="profit-green">{userData && parseFloat(userData.withdrawable || 0).toFixed(2)} USDT</span></p>
																<p className="text-gray mb-3" style={{fontSize:"14px"}}>*此收益根据旧数据结算至9月12日凌晨00:00时</p>
															</>
														):(
															<></>
														)}

														{userData && userData.total_deposit >= 50 ? (
															// <p className="text-white mb-0" style={{fontSize:"14px"}}>托管收益: <span className="profit-green">{userData && parseFloat(activeDeposit*12/1000*firstDepositTime/86400000).toFixed(2)} USDT</span></p>
															<p className="text-white mb-0" style={{fontSize:"14px"}}>托管收益: <span className="profit-green">{userData && parseFloat(userData.stakeEarning || 0).toFixed(2)} USDT</span></p>
														):(
															<p className="text-white mb-0" style={{fontSize:"14px"}}>托管收益: <span className="error">还未托管</span></p>
														)}

														{referralComm > 0 ? (
															<p className="text-white mb-0" style={{fontSize:"14px"}}>社区奖励: <span className="profit-green">{parseFloat(userData.teamBonus || 0).toFixed(2)} USDT</span></p>
															// <p className="text-white mb-0" style={{fontSize:"14px"}}>社区奖励: <span className="profit-green">{parseFloat(referralComm*firstDepositTime/86400000).toFixed(2)} USDT</span></p>
														):(
															<p className="text-white mb-0" style={{fontSize:"14px"}}>社区奖励: <span className="error">还未达标</span></p>
														)}

														{userData && userData.rank >= 1 ? (
															<>
																{/* {v3Deadline > 0 ?
																	<p className="text-white mb-0" style={{fontSize:"14px"}}>星级奖励: <span className="profit-green">{userData && parseFloat((userData.total_team_sales*0.012*rankBonusPercentages[userData.rank]*v3Deadline/86400000)+(userData.rankBonus)).toFixed(2)} USDT</span></p>
																	:
																	<p className="text-white mb-0" style={{fontSize:"14px"}}>星级奖励: <span className="profit-green">{userData.rankBonus ? parseFloat(userData.rankBonus).toFixed(2) : parseFloat(0).toFixed(2)} USDT</span></p>
																} */}
																<p className="text-white mb-0" style={{fontSize:"14px"}}>星级奖励: <span className="profit-green">{userData.rankBonus ? parseFloat(userData.rankBonus).toFixed(2) : parseFloat(0).toFixed(2)} USDT</span></p>
															</>
														):(
															<p className="text-white mb-0" style={{fontSize:"14px"}}>星级奖励: <span className="error">还未达标</span></p>
														)}

														{userData && userData.rank >= 3 ? (
															<p className="text-white mb-0" style={{fontSize:"14px"}}>平级奖励: <span className="profit-green">{userData.sameRankBonus ? parseFloat(userData.sameRankBonus).toFixed(2) : parseFloat(0).toFixed(2)} USDT</span></p>
															// <p className="text-white mb-0" style={{fontSize:"14px"}}>平级奖励: <span className="yellow">数据重整中</span></p>
														):(
															<p className="text-white mb-0" style={{fontSize:"14px"}}>平级奖励: <span className="error">还未达标</span></p>
														)}

														{userData && userData.rank >= 3 ? (
															<p className="text-white mb-0" style={{fontSize:"14px"}}>加权奖励: <span className="profit-green">{userData.rankedProfitShare ? parseFloat(userData.rankedProfitShare).toFixed(2) : parseFloat(0).toFixed(2)} USDT</span></p>
															// <p className="text-white mb-0" style={{fontSize:"14px"}}>加权奖励: <span className="yellow">数据重整中</span></p>
														):(
															<p className="text-white mb-0" style={{fontSize:"14px"}}>加权奖励: <span className="error">还未达标</span></p>
														)}

														<p className="text-gray mt-3 mb-0" style={{fontSize:"14px"}}>托管收益 - 每日22:00时结算</p>
														<p className="text-gray mt-0 mb-0" style={{fontSize:"14px"}}>社区，星级，平级和加权奖励 - 每日00:00时结算</p>
													</>
												):(
													<>
													{userData && userData.total_deposit == 0 ? (
														<p className="text-gray mb-0" style={{fontSize:"16px"}}>用户还没开始托管</p>
													):(
														<p className="pink mb-0" style={{fontSize:"16px"}}>已出局</p>
													)}
													</>
												)}
											</td>
										</tr>
									</tbody>
									// <tbody>
									// 	<tr>
									// 		<td className="py-2"><p className="yellow mb-0" style={{fontSize:"14px"}}>系统正在升级中</p></td>
									// 	</tr>
									// </tbody>
								)}
							</table>
						</div>
					</div>

					{/* Withdrawal Records */}
					<div className="col-12 mt-2 mt-md-3 px-2">
						<button className="btn w-100 text-left data-box" onClick={(e) => {
							setExpandWithdrawDetails(!expandWithdrawDetails);
						}}>
							<div className="row">
								<div className="col-12">
									<h5 className="blue mb-0">我的总收益:</h5>
									{loaderDelay ? (
										<p className="text-white mb-0" style={{fontSize:"14px"}}>正在加载中。。。</p>
									):(
										<p className="token-balance text-white mt-1 mb-0">
											<span className="profit-green">
												{parseFloat(userTotalWithdraw).toFixed(2)} / {userData && parseFloat(userData.total_deposit*3).toFixed(2)}
											</span> USDT 
										</p>
									)}
								</div>
							</div>
							{!loaderDelay && expandWithdrawDetails ? (
								<>
									{userWithdrawalData && userWithdrawalData.length > 0 ? (
										<table className="table borderless mt-3">
											<thead>
												<tr>
													<th scope="col" className="py-2"><p className="blue mb-0" style={{fontSize:"16px"}}>提现记录：</p></th>
												</tr>
											</thead>
											<tbody>
												{userWithdrawalData && userWithdrawalData.map((withdrawal, index) => (
													<tr key={index}>
														<td className="py-1">
															<p className="text-white mb-0" style={{fontSize:"14px"}}>
																{Moment(withdrawal.createdAt).format('YYYY/MM/DD')} <span className="profit-green">+{parseFloat(withdrawal.withdrawal_amount).toFixed(2)} USDT</span>
															</p>
															{withdrawal.status == "Processing" ? (
																<p className="yellow mb-0" style={{fontSize:"14px"}}>正在处理中</p>
															):(
																<>
																	<p className="profit-green mb-0" style={{fontSize:"14px"}}>提现已到账</p>
																	<p className="text-white mb-0" style={{fontSize:"14px"}}><a href={withdrawal.txn_link} target="_blank">查看区块链</a></p>
																</>
															)}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									):(
										<p className="text-gray mt-3 mb-0">此账户没有提现记录。</p>
									)}
								</>
							):(
								<></>
							)}
						</button>
					</div>

				</div>
			</div>

			{/* Staking Modal */}
			<div class="modal fade" id="stakeModal" tabindex="-1" data-backdrop="static" data-keyboard="false" aria-labelledby="stakeModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content invest-modal py-3">
						<div class="modal-body text-center px-3">
							{allowance <= 50000000000000000000 ? (
								<>
									{!approving ? (
										<>
											<h5 className="text-white mb-0">授权 USDT 支付 (只需要完成一次)</h5>
											<button className="btn btn-primary mx-2 mt-3" onClick={(e) => {
												approveToken();
											}}>
												<h5 className="text-white mb-0" style={{fontSize:"16px"}}>授权 USDT</h5>
											</button>
											<button className="btn btn-danger mx-2 mt-3" data-dismiss="modal" onClick={(e) => {
											}}>
												<h5 className="text-white mb-0" style={{fontSize:"16px"}}>取消</h5>
											</button>
										</>
									):(
										<h5 className="text-white mb-0" style={{fontSize:"16px"}}>授权手续正在进行中。。。</h5>
									)}

								</>
							):(
								<>
									{!investing ? (
										<>	
											{!investSuccess ? (
												<>
													<h5 className="text-white">USDT 托管 - 每日 <span className="token-balance profit-green"><strong>1.2%</strong></span></h5>
													<input
														type="text"
														step="0.01"
														placeholder='输入托管数额'
														autoComplete='off'
														name='amount'
														onChange={handleAmountInput}
													/>
													<h5 className="text-white mt-2 mb-0" style={{fontSize:"14px"}}><span className="blue">可用: </span>{parseFloat(usdtBalance/1000000000000000000).toFixed(2)} USDT</h5>
													
													{balError != "" ? <h5 className="error mt-2 mb-0" style={{fontSize:"16px", fontWeight:"700"}}>{balError}</h5> : <></>}
													
													<button disabled={invalidAmount || balError != ""} className="btn btn-primary mx-2 mt-3" onClick={(e) => {
														investUSDT();
													}}>
														<h5 className="text-white mb-0" style={{fontSize:"16px"}}>确认托管</h5>
													</button>
													<button className="btn btn-danger mx-2 mt-3" data-dismiss="modal" onClick={(e) => {
													}}>
														<h5 className="text-white mb-0" style={{fontSize:"16px"}}>取消</h5>
													</button>
												</>
											):(
												<>
													<span style={{fontSize:"36px"}}><i className="profit-green fa-sharp fa-solid fa-circle-check mr-2"></i></span>
													<h5 className="text-white mt-2 mb-0">托管成功, 托管详情会稍后自动更新。</h5>
													<button className="btn btn-primary mx-2 mt-3" data-dismiss="modal" onClick={(e) => {
														setTimeout(() => setInvestSuccess(false), 1000);
													}}>
														<h5 className="text-white mb-0" style={{fontSize:"16px"}}>完成</h5>
													</button>
												</>
											)}

										</>
									):(
										<h5 className="text-white mb-0" style={{fontSize:"16px"}}>托管手续正在进行中。。。</h5>
									)}

								</>
							)}
						</div>
					</div>
				</div>
			</div>
			
			{/* Staking Product */}
			<div className="container mt-5">
				<div className="row">
					<div className="col-12">
						<h3 className="text-white"><i className="fa-solid fa-hand-holding-circle-dollar mx-2"></i>托管产品</h3>
					</div>

					{/* USDT Staking */}
					<div className="col-12 mt-2">
						<div className="data-box">
							<div className="row">
								<div className="col-sm-8">
									<h4 className="text-white mb-0">
										<span className="">
											<img className="token-icon mr-2" src={USDTTokenIcon} alt="USDT Token Icon"></img>
										</span>
										$USDT
									</h4>
									<p className="text-white mt-1 mb-0">最低 50 USDT, 日收益 <span className="token-balance profit-green"><strong>1.2%</strong></span></p>
								</div>
								<div className="col-sm-4 text-left text-sm-right">
									{loaderDelay ? (
										<p className="text-white mb-0" style={{fontSize:"14px"}}>正在加载中。。。</p>
									):(
										<>
											{userData && ((userData.total_deposit*3) - (userData.total_withdraw || 0)) > (userData.withdrawable || 0)+(userData.stakeEarning || 0)+(userData.teamBonus || 0)+(userData.sameRankBonus || 0)+(userData.rankedProfitShare || 0)+(userData.rankBonus || 0) ? (
												<button className="btn btn-primary mt-2 mt-sm-0" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
												}}>
													<p className="text-white mb-0">托管</p>
												</button>
												// <p className="yellow mb-0" style={{fontSize:"16px"}}>升级中</p>
											):(
												<>	
													{userData.total_withdraw >= (userData.total_deposit*3) ? (
														<>
															<button className="btn btn-primary mt-2 mt-sm-0" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
															}}>
																<p className="text-white mb-0">托管</p>
															</button>
															{/* <p className="yellow mb-0" style={{fontSize:"16px"}}>升级中</p> */}
														</>
													):(
														<>
															<p className="pink mb-0" style={{fontSize:"14px"}}>已出局</p>
															<p className="text-gray mb-0" style={{fontSize:"14px"}}>提现后可以再复投</p>
														</>
													)}

												</>
											)}
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	);

};

export default Invest;