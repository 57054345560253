import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";

const Account = () => {

	const blockchain = useSelector((state) => state.blockchain);

	const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyReferral = () => {    
	  setCopied(true);
	  setCopyText("复制成功！");
	  setTimeout(() => setCopied(false), 3000);
	}

	const [userData, setUserData] = useState();
	const [userDirectReferrals, setUserDirectReferrals] = useState();

	const [mainTeamSales, setMainTeamSales] = useState(0);
    const [subTeamsSales, setSubTeamsSales] = useState(0);

	const [mainTeamCount, setMainTeamCount] = useState(0);
	const [subTeamsMemberCount, setSubTeamsMemberCount] = useState(0);

    const fetchUserData= async () => {
        const response = await fetch('https://api.tradevisionai.com/user/' + blockchain.account);
        const json = await response.json();
        if (response.ok) {
            if (json.length === 1) {
                // Expect only 1 result
                setUserData(json[0]);
				getUserDirectReferrals(json[0].total_team_sales, json[0].total_team_count);
            } else {
                console.log("Invalid user data: More than 1 result.");
            }
        }
    }
	
    const getUserDirectReferrals = async (totalTeamSales, totalTeamCount) => {
        const response = await fetch('https://api.tradevisionai.com/user/referral/' + blockchain.account);
        const json = await response.json();
        if (response.ok) {
            setUserDirectReferrals(json);
            calculateSales(json, totalTeamSales, totalTeamCount);
        }
    }

	const calculateSales = (referrals, totalTeamSales, totalTeamCount) => {
        let highestSales = 0;
        let mainTeamSalesAmount = 0;
		let mainReferralTeamCount = 0;

        referrals.forEach(referral => {
            const referralTotalSales = referral.total_deposit + referral.total_team_sales;
            if (referralTotalSales > highestSales) {
                highestSales = referralTotalSales;
                mainTeamSalesAmount = referralTotalSales;
				mainReferralTeamCount = referral.total_team_count;
            }
        });

        setMainTeamSales(mainTeamSalesAmount);
        setSubTeamsSales(totalTeamSales - mainTeamSalesAmount);

		setMainTeamCount(mainReferralTeamCount + 1);
		setSubTeamsMemberCount(totalTeamCount - mainReferralTeamCount - 1);
    }

	useEffect(() => {
		console.log("Account Page", blockchain.account);
		// getUserData();
		fetchUserData();
		getUserDirectReferrals();
	}, []);

	return (
		<>
		<div className="main-content">
			<div className="container mt-5">
				<div className="row">
					<div className="col-12">
						<h3 className="text-white"><i className="fa-solid fa-user mx-2"></i>我的账户</h3>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6 mt-3 px-2">
						<div className="data-box">
							<h5 className="blue mb-0">钱包地址:</h5>
							<MiddleEllipsis><span className="text-white mt-1 mb-0">{blockchain.account}</span></MiddleEllipsis>
							<h5 className="blue mt-3 mb-0">我的上级:</h5>
							<MiddleEllipsis><span className="text-white mt-1 mb-0">{userData && userData.referrer}</span></MiddleEllipsis>
						</div>
					</div>
					<div className="col-md-6 mt-2 mt-md-3 px-2">
						<div className="data-box">
							<h5 className="blue mb-0">账户星级</h5>
							<div className="d-flex justify-content-left">
								<span className={userData && userData.rank >= 1 ? "yellow" : "gray"}><i className="fa-sharp fa-solid fa-star"></i></span>
								<span className={userData && userData.rank >= 2 ? "yellow" : "gray"}><i className="fa-sharp fa-solid fa-star"></i></span>
								<span className={userData && userData.rank >= 3 ? "yellow" : "gray"}><i className="fa-sharp fa-solid fa-star"></i></span>
								<span className={userData && userData.rank >= 4 ? "yellow" : "gray"}><i className="fa-sharp fa-solid fa-star"></i></span>
								<span className={userData && userData.rank >= 5 ? "yellow" : "gray"}><i className="fa-sharp fa-solid fa-star"></i></span>
							</div>
							<h5 className="blue mt-3 mb-0">团队人数:</h5>
							<p className="text-white mt-1 mb-0">{userData && userData.total_team_count}</p>
						</div>
					</div>
					{userData ? (
						<div className="col-12 mt-2 mt-md-3 px-2">
							<div className="data-box">
								<div className="row">
									<div className="col-md-8">
										<h5 className="blue mb-0"><i className="fa-solid fa-link mr-2"></i>推荐链接</h5>
										{userData && userData.total_deposit >= 50 ? (
											<MiddleEllipsis><span className="text-white mt-1 mb-0" style={{fontSize:"12px"}}>https://tradevisionai.com/{blockchain.account}</span></MiddleEllipsis>
										):(
											<span className="text-white mt-1 mb-0" style={{fontSize:"14px"}}>个人托管 50 USDT 或以上就能开启推荐链接。</span>
										)}
									</div>
									{userData && userData.total_deposit >= 50 ? (
										<div className="col-md-4 text-left text-md-right">
											<CopyToClipboard 
												text={"https://tradevisionai.com?referral="+blockchain.account.toLowerCase()}
												onCopy={() => copyReferral()}>
												<button className="btn mt-2 btn-primary" onClick={(e) => {
												}}>
													{!copied ? (
														<p className="mb-0">复制链接</p>
													):(
														<p className="mb-0">{copyText}</p>
													)}
												</button>
											</CopyToClipboard>
										</div>
									):(
										<div className="col-md-4 text-left text-md-right">
											<NavLink to="/invest">
												<button className="btn mt-2 btn-primary" onClick={(e) => {
												}}>
													<p className="mb-0">开始托管</p>
												</button>
											</NavLink>
										</div>
									)}
								</div>
							</div>
						</div>
					):(
						<></>
					)}
				</div>
			</div>

			<div className="container mt-5">
				<div className="row">
					<div className="col-12">
						<h3 className="text-white"><i className="fa-duotone fa-solid fa-users mx-2"></i>团队统计</h3>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4 mt-2 px-2">
						<div className="data-box">
							<h5 className="text-white mb-0">推荐人数:</h5>
							<p className="text-white mt-1 mb-0">{userDirectReferrals && userDirectReferrals.length}</p>
							<h5 className="text-white mt-3 mb-0">个人业绩:</h5>
							<p className="profit-green mt-1 mb-0">${userData && parseFloat(userData.total_personal_sales).toFixed(2)}</p>
						</div>
					</div>
					<div className="col-md-4 mt-2 px-2">
						<div className="data-box">
							<h5 className="text-white mb-0">大区人数:</h5>
							{userDirectReferrals && userDirectReferrals.length > 0 ? (
								<p className="text-white mt-1 mb-0">{mainTeamCount}</p>
							):(
								<p className="text-white mt-1 mb-0">0</p>
							)}
							<h5 className="text-white mt-3 mb-0">大区业绩:</h5>
							{userDirectReferrals && userDirectReferrals.length > 0 ? (
								<p className="profit-green mt-1 mb-0">${parseFloat(mainTeamSales).toFixed(2)}</p>
							):(
								<p className="profit-green mt-1 mb-0">$0.00</p>
							)}
						</div>
					</div>
					<div className="col-md-4 mt-2 px-2">
						<div className="data-box">
							<h5 className="text-white mb-0">小区人数:</h5>
							{userData && userDirectReferrals && userDirectReferrals.length > 0 ? (
								<p className="text-white mt-1 mb-0">{subTeamsMemberCount}</p>
							):(
								<p className="text-white mt-1 mb-0">0</p>
							)}
							<h5 className="text-white mt-3 mb-0">小区业绩:</h5>
							{userData && userDirectReferrals && userDirectReferrals.length > 0 ? (
								<p className="profit-green mt-1 mb-0">${parseFloat(subTeamsSales).toFixed(2)}</p>
							):(
								<p className="profit-green mt-1 mb-0">$0.00</p>
							)}
						</div>
					</div>

				</div>
			</div>

			<div className="container mt-5">
				<div className="row">
					<div className="col-12">
						<h3 className="text-white"><i className="fa-solid fa-link mx-2"></i>我的推荐</h3>
					</div>
				</div>
				<div className="row">
					<div className="col-12 mt-2 px-2">

						{userDirectReferrals && userDirectReferrals.length > 0 && userData && userData.total_deposit >= 50 ? (
							<div className="table-container details-table">
								<table className="table borderless">
									<thead>
										<tr>
											<th scope="col" className="py-2"><p className="blue mb-0" style={{fontSize:"14px"}}>#</p></th>
											<th scope="col" className="py-2"><p className="blue mb-0" style={{fontSize:"14px"}}>会员钱包地址</p></th>
											<th scope="col" className="py-2"><p className="blue mb-0" style={{fontSize:"14px"}}>托管</p></th>
											<th scope="col" className="py-2"><p className="blue mb-0" style={{fontSize:"14px"}}>团队业绩</p></th>
											<th scope="col" className="py-2"><p className="blue mb-0" style={{fontSize:"14px"}}>推荐</p></th>
										</tr>
									</thead>
									<tbody>
										{userDirectReferrals && userDirectReferrals.map((teamUser, index) => (
											<tr key={index}>
												<td className="py-2"><p className="text-white mb-0" style={{fontSize:"14px"}}>{index+1}</p></td>
												<td className="py-2" style={{maxWidth:"160px"}}>
													<MiddleEllipsis>
														<span className="text-white mb-0" style={{fontSize:"14px"}}>{teamUser.wallet}</span>
													</MiddleEllipsis>
													<div className="d-flex justify-content-left">
														<span className={teamUser && teamUser.rank >= 1 ? "yellow" : "gray"}><i className="fa-sharp fa-solid fa-star"></i></span>
														<span className={teamUser && teamUser.rank >= 2 ? "yellow" : "gray"}><i className="fa-sharp fa-solid fa-star"></i></span>
														<span className={teamUser && teamUser.rank >= 3 ? "yellow" : "gray"}><i className="fa-sharp fa-solid fa-star"></i></span>
														<span className={teamUser && teamUser.rank >= 4 ? "yellow" : "gray"}><i className="fa-sharp fa-solid fa-star"></i></span>
														<span className={teamUser && teamUser.rank >= 5 ? "yellow" : "gray"}><i className="fa-sharp fa-solid fa-star"></i></span>
													</div>
												</td>
												<td className="py-2"><p className="text-white mb-0" style={{fontSize:"14px"}}>{parseFloat(teamUser.total_deposit).toFixed(2)}</p></td>
												<td className="py-2"><p className="text-white mb-0" style={{fontSize:"14px"}}>{teamUser.total_team_sales > 0 ? parseFloat(teamUser.total_team_sales).toFixed(2) : 0}</p></td>
												<td className="py-2"><p className="text-white mb-0" style={{fontSize:"14px"}}>{teamUser.referral_count}</p></td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						):(
							<div className="data-box">
								<div className="row">
									<div className="col-12">
										<p className="text-gray mb-0">此账户还未有推荐。</p>
									</div>
								</div>
							</div>
						)}
						
					</div>
				</div>
			</div>
		</div>
		</>
	);

};

export default Account;