import { useState } from 'react';

export const useWithdrawal = () => {
    const [isWithdrawing, setIsWithdrawing] = useState(null);

    const submitWithdraw = async (curUserID, userWallet, withdrawAmount, aimmFee) => {
        setIsWithdrawing(true);

        const response = await fetch('https://api.tradevisionai.com/withdrawal/create', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                user_id: curUserID,
                wallet: userWallet,
                withdrawal_amount: withdrawAmount,
                fee: aimmFee
            })
        })
        const json = await response.json();
        if (!response.ok) {
            setIsWithdrawing(false);
        }
        if (response.ok) {
            setIsWithdrawing(false);
        }
    }

    return { submitWithdraw, isWithdrawing };
}