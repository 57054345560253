import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import HelperContract from "../../contracts/HelperContract.json";
import TradeVisionAIDeposit from "../../contracts/TradeVisionAIDeposit.json";
import TradeVisionAIDepositV2 from "../../contracts/DepositV2.json";
import USDTContract from "../../contracts/USDTContract.json";
import TestToken from "../../contracts/TestToken.json";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const { ethereum } = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (metamaskIsInstalled) {
      Web3EthContract.setProvider(ethereum);
      let web3 = new Web3(ethereum);
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        const networkId = await ethereum.request({
          method: "net_version",
        });
        if (networkId == 56) {
          const HelperContractObj = new Web3EthContract(
            HelperContract.abi,
            "0x375413a2Dd233852cdE614F4D1409B281240be2e"
          );
          const TradeVisionAIDepositObj = new Web3EthContract(
            TradeVisionAIDeposit.abi,
            "0x8dF618b778167AA079cAec1d6Ad154777b4330e9"
          );
          const TradeVisionAIDepositV2Obj = new Web3EthContract(
            TradeVisionAIDepositV2.abi,
            "0xd2E1236456B07B0baD066344659D161a171CCAE2"
          );
          const USDTContractObj = new Web3EthContract(
            USDTContract.abi,
            "0x55d398326f99059fF775485246999027B3197955"
          );
          const TestTokenObj = new Web3EthContract(
            TestToken.abi,
            "0x1504b7aB575969261BD27A5F9f63f24d284090D2"
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              helperContract: HelperContractObj,
              tradeVisionAIDeposit: TradeVisionAIDepositObj,
              tradeVisionAIDepositV2: TradeVisionAIDepositV2Obj,
              usdtContract: USDTContractObj,
              testToken: TestTokenObj,
              web3: web3,
            })
          );
          // Add listeners start
          ethereum.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
            alert("钱包已被更换，请重新登入D-App。");
            window.location.reload();
          });
          ethereum.on("chainChanged", () => {
            alert("成功转换区块链，请重新链接钱包。");
            window.location.reload();
          });
          // Add listeners end
        } else {
          alert("请转换使用币安（BSC）区块链。");
        }
      } catch (err) {
        alert("请链接区块链钱包使用D-App。");
      }
    } else {
      // dispatch(connectFailed("Please install Metamask to use our application."));
      alert("请链接区块链钱包使用D-App。");
    }
  };
  
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
  };
};

