import { useState } from 'react';

export const useSignup = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const walletSignup = async (wallet, referrer) => {
        setIsLoading(true);
        setError(null);

        var finalReferrer;
        // Fetch Referrer Data
        const referrerData = await fetch('https://api.tradevisionai.com/user/'+referrer);
		const data = await referrerData.json();
		if (referrerData.ok) {
			if (data.length === 1) {
                if (data[0].total_deposit >= 50 && referrer != "0xa6ff14636c31fa4799d742185f723522c8f2ab48") {
                    finalReferrer = referrer;
                } else {
                    console.log("Invalid referrer data, expected only 1.");
                    finalReferrer = "0xa6ff14636c31fa4799d742185f723522c8f2ab48";
                }
			} else {
				console.log("Invalid referrer data, expected only 1.");
                finalReferrer = "0xa6ff14636c31fa4799d742185f723522c8f2ab48";
			}
		} else {
            console.log("Error to fetch referrer data.");
            finalReferrer = "0xa6ff14636c31fa4799d742185f723522c8f2ab48";
        }

        if (finalReferrer != "") {
            const response = await fetch('https://api.tradevisionai.com/user/register', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    wallet,
                    referrer: finalReferrer,
                })
            })
            const json = await response.json();
            if (!response.ok) {
                setIsLoading(false);
                setError(json.error);
            }
            if (response.ok) {
                setIsLoading(false);
            }
        }

    }

    return { walletSignup, isLoading, error };
}