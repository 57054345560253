import React, { useEffect, useState } from 'react';

const Trading = () => {

	useEffect(() => {

	}, []);

	return (
		<>
		<div className="main-content">
			<div className="container mt-5">
				<div className="row">
					<div className="col-12">
						<h3 className="text-white"><i className="fa-duotone fa-solid fa-money-bill-transfer mx-2"></i>交易中心</h3>
					</div>
				</div>
				<div className="row">
					<div className="col-12 mt-2">
						<div className="data-box">
							<p className="text-gray mb-0">即将上线。。。</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	);

};

export default Trading;