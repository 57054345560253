import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";
import Moment from 'moment';

import AIMMTokenIcon from '../assets/img/aimm-token.png';
import USDTTokenIcon from '../assets/img/usdt-token.png';
import BNBTokenIcon from '../assets/img/bnb-token.jpg';

import AIMMDepositQR from '../assets/img/aimm-deposit-wallet.png';

// hooks
import { useRecharge } from '../hooks/useRecharge';

const Wallet = () => {

	const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyReferral = () => {    
	  setCopied(true);
	  setCopyText("复制成功！");
	  setTimeout(() => setCopied(false), 3000);
	}

	const [userData, setUserData] = useState();

	const getUserData = async () => {
		const response = await fetch('https://api.tradevisionai.com/user/'+blockchain.account);
		const json = await response.json();
		if (response.ok) {
			if (json.length === 1) {
				// Expect only 1 result
				setUserData(json[0]);
				console.log("userData:", json[0]);
			} else {
				console.log("Invalid user data: More than 1 result.")
			}
		}
	}

	const blockchain = useSelector((state) => state.blockchain);

	const [usdtBalance, setUSDTBalance] = useState(0);
	const getUserUSDTBalance = () => {
	  blockchain.helperContract.methods.getTokenBalance(blockchain.account, "0x55d398326f99059fF775485246999027B3197955").call()
	  .then((results) => {
		console.log("USDT Balance:", results);
		setUSDTBalance(results);
	  });
	};

	const [bnbBalance, setBNBBalance] = useState(0);
	const getUserBNBBalance = () => {
	  blockchain.helperContract.methods.getBalance(blockchain.account).call()
	  .then((results) => {
		console.log("BNB Balance:", results);
		setBNBBalance(results);
	  });
	};

	const [aimmPrice, setAimmPrice] = useState();
	const fetchAimmFromDex = async () => {
		const response = await fetch('https://api.dexscreener.com/latest/dex/pairs/solana/HLWyH4kaeVimURK25aWShaaCas3CdjBndmusgjHVKVQq');
		const json = await response.json();
		if (response.ok) {
			// console.log(json.pair.priceUsd);
			setAimmPrice(json.pair.priceUsd);
		}
	}

	const [amount, setAmount] = useState();
	const [balError, setBalError] = useState('');
	const [invalidAmount, setInvalidAmount] = useState(true);
	const handleAmountInput = (e) => {
		const value = e.target.value;
		e.preventDefault();
		if (isNaN(+value)) {
			setInvalidAmount(true);
			setBalError('必须输入数字！');
		} else if (value <= 0) {
			setInvalidAmount(true);
			setBalError('充值数额不能少于零！');
		} else {
			setInvalidAmount(false);
			setBalError('');
		}
		setAmount(e.target.value);
	};

	
	const {createRecharge, uploadTXN, error, isLoading} = useRecharge();

	const submitRecharge = async (e) => {
		e.preventDefault();
		await createRecharge(userData._id, blockchain.account, amount);
		fetchUserRechargeData();
	};

	const [txnLink, setTxnLink] = useState('');
	const handleLinkChange = (e) => {
		e.preventDefault();
		setTxnLink(e.target.value);
	};

	const submitTXNLink = async (e) => {
		e.preventDefault();
		await uploadTXN(selectedRechargeOrder._id, txnLink);
		setTxnLink('');
		const response = await fetch('https://api.tradevisionai.com/recharge/id/'+selectedRechargeOrder._id);
		const json = await response.json();
		if (response.ok) {
			setSelectedRechargeOrder(json);
		}
		fetchUserRechargeData();
	};

	const [selectedRechargeOrder, setSelectedRechargeOrder] = useState();
	const [userRechargeData, setUserRechargeData] = useState();
	const fetchUserRechargeData = async () => {
		const response = await fetch('https://api.tradevisionai.com/recharge/wallet/'+blockchain.account);
		const json = await response.json();
		if (response.ok) {
			setUserRechargeData(json);
		}
	}

	useEffect(() => {
		fetchAimmFromDex();
		getUserData();
		fetchUserRechargeData();
		getUserUSDTBalance();
		getUserBNBBalance();
	}, []);

	useEffect(() => {
		console.log("Wallet Page", blockchain.account);
	}, []);


	return (
		<>
		<div className="main-content">
			{/* Modal */}
			<div class="modal fade" id="rechargeModal" tabindex="-1" data-backdrop="static" data-keyboard="false" aria-labelledby="rechargeModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content invest-modal py-3">
						<div class="modal-body text-center px-3">
							<h4 className="text-white">充值状态</h4>
							<h5 className="yellow">正在确认转送手续</h5>
							<h6 className="text-white mt-5">请将<span className="blue"> {amount} AIMM </span>转送至以下钱包以完成充值</h6>
							<img className="w-100 my-3" style={{maxWidth:"150px"}} src={AIMMDepositQR}></img>
							<MiddleEllipsis>
								<span className="text-white" style={{fontSize:"14px"}}>4zpQEHAWBGa3nt9vU1uVau5BYssavHeoCCscZhKqJMN</span>
							</MiddleEllipsis>
							<CopyToClipboard 
								text={"4zpQEHAWBGa3nt9vU1uVau5BYssavHeoCCscZhKqJMN"}
								onCopy={() => copyReferral()}>
								<button className="btn mt-3 btn-primary" onClick={(e) => {
								}}>
									{!copied ? (
										<p className="mb-0">复制钱包地址</p>
									):(
										<p className="mb-0">{copyText}</p>
									)}
								</button>
							</CopyToClipboard>
							<br></br>
							{selectedRechargeOrder && selectedRechargeOrder.txn_link == "" ? (
								<>
									<h6 className="text-white mt-5">用户完成转送 AIMM 后必须上转 SOL 钱包地址确认充值手续</h6>
									<form className="" onSubmit={submitTXNLink}>
										<input
											type="text"
											placeholder='上转 SOL 钱包地址'
											autoComplete='off'
											name='txn'
											onChange={handleLinkChange}
										/>
										<button disabled={txnLink == ''} className="btn mt-3 btn-primary mr-2" type="submit" onClick={(e) => {
										}}>
											<p className="mb-0">确认 SOL 钱包地址</p>
										</button>
										<button className="btn mt-3 btn-danger" data-dismiss="modal" onClick={(e) => {
											setInvalidAmount(true);
											setBalError('');
											setAmount('');
										}}>
											<p className="mb-0">退出</p>
										</button>
									</form>

								</>
							):(
								<>
									<h6 className="text-white mt-5 mb-0">SOL 钱包地址:</h6>
									<MiddleEllipsis>
										<span className="text-white" style={{fontSize:"14px"}}>{selectedRechargeOrder && selectedRechargeOrder.txn_link}</span>
									</MiddleEllipsis>
									<button className="btn mt-3 btn-danger" data-dismiss="modal" onClick={(e) => {
										setInvalidAmount(true);
										setBalError('');
										setAmount('');
									}}>
										<p className="mb-0">退出</p>
									</button>
								</>
							)}

						</div>
					</div>
				</div>
			</div>

			<div className="container mt-5">
				<div className="row">
					<div className="col-12">
						<h3 className="text-white"><i className="fa-duotone fa-solid fa-wallet mx-2"></i>我的钱包</h3>
					</div>
				</div>
				<div className="row">

					<div className="col-12 mt-2 px-2">
						<div className="data-box">
							<div className="row">
								<div className="col-sm-5">
									<h4 className="text-white mb-0">
										<span className="">
											<img className="token-icon mr-2" src={BNBTokenIcon} alt="BNB Token Icon"></img>
										</span>
										BNB
									</h4>
									<p className="token-balance profit-green mb-0">{parseFloat(bnbBalance/1000000000000000000).toFixed(5)}</p>
								</div>
								<div className="col-sm-7 text-left text-sm-right">
									{/* <button disabled className="btn btn-primary mt-2 mt-sm-0" onClick={(e) => {
									}}>
										<p className="text-white mb-0">交易</p>
									</button> */}
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 mt-2 px-2">
						<div className="data-box">
							<div className="row">
								<div className="col-sm-5">
									<h4 className="text-white mb-0">
										<span className="">
											<img className="token-icon mr-2" src={USDTTokenIcon} alt="USDT Token Icon"></img>
										</span>
										USDT
									</h4>
									<p className="token-balance profit-green mt-2 mb-0">{parseFloat(usdtBalance/1000000000000000000).toFixed(5)}</p>
								</div>
								<div className="col-sm-7 text-left text-sm-right">
									<NavLink to="/invest">
										<button className="btn btn-primary mt-2 mt-sm-0 mr-2" onClick={(e) => {
											window.scrollTo(0, 0);
										}}>
											<p className="text-white mb-0">托管</p>
										</button>
									</NavLink>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 mt-2 px-2">
						<div className="data-box">
							<div className="row">
								<div className="col-sm-5">
									<h4 className="text-white mb-0">
										<span className="">
											<img className="token-icon mr-2" src={AIMMTokenIcon} alt="AIMM Token Icon"></img>
										</span>
										AIMM
										<span className="text-gray small-font ml-2">
											${aimmPrice}
										</span>
									</h4>
									
										{userData && userData.aimm_balance !== undefined ? (
											
											<p className="token-balance profit-green mb-0">
												{parseFloat(userData.aimm_balance).toFixed(5)}
												<br></br>
												<span className="text-gray small-font"> ≈ ${aimmPrice && parseFloat(userData.aimm_balance*aimmPrice).toFixed(2)} USDT</span>
											</p>
											
										):(
											<p className="token-balance profit-green mb-0">
												{parseFloat(0).toFixed(5)}
											</p>
										)}
									
								</div>
								<div className="col-sm-7 text-left text-sm-right">
									<button className="btn btn-primary mt-2 mt-sm-0 mr-2" onClick={(e) => {
										window.open("https://dexscreener.com/solana/hlwyh4kaevimurk25awshaacas3cdjbndmusgjhvkvqq", "_blank");
									}}>
										<p className="text-white mb-0">DEX</p>
									</button>
									<button className="btn btn-primary mt-2 mt-sm-0 mr-2" onClick={(e) => {
										window.open("https://raydium.io/swap/?inputMint=sol&outputMint=7n8Ye4vcEsNVkUREqbKwsQojypiHtGJShRcYs12DZXCD", "_blank");
									}}>
										<p className="text-white mb-0">交易</p>
									</button>
								</div>
								<div className="col-12 mt-5">
									<h5 className="text-white mb-0">充值 AIMM:</h5>
									<form className="w-100" onSubmit={submitRecharge}>
										<input
											className="text-left mt-2"
											type="text"
											placeholder='输入充值 AIMM 数额'
											autoComplete='off'
											name='amount'
											value={amount}
											onChange={handleAmountInput}
										/>

										{balError != "" ? <h5 className="error mt-2 mb-0" style={{fontSize:"14px"}}>{balError}</h5> : <></>}

										<button disabled={invalidAmount || balError != ""} className="btn btn-primary mt-2" type="submit" data-toggle="modal" data-target="#rechargeModal" onClick={(e) => {
											setTimeout(() => setSelectedRechargeOrder(userRechargeData[0]), 1000);
										}}>
											<p className="text-white mb-0">充值 AIMM</p>
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container mt-5">
				<div className="row">
					<div className="col-12">
						<h3 className="text-white"><i className="fa-solid fa-clipboard-list mx-2"></i>我的充值记录</h3>
					</div>
				</div>
				<div className="row">
					<div className="col-12 mt-2 px-2">
						<div className="table-container details-table">
							{userRechargeData && userRechargeData.length > 0 ? (
								<table className="table borderless">
									<thead>
										<tr>
										<th scope="col" className="py-2"><p className="blue mb-0" style={{fontSize:"14px"}}>#</p></th>
										<th scope="col" className="py-2"><p className="blue mb-0" style={{fontSize:"14px"}}>资产</p></th>
										<th scope="col" className="py-2"><p className="blue mb-0" style={{fontSize:"14px"}}>充值数额</p></th>
										<th scope="col" className="py-2"><p className="blue mb-0" style={{fontSize:"14px"}}>状态</p></th>
										<th scope="col" className="py-2"><p className="blue mb-0" style={{fontSize:"14px"}}>时间</p></th>
										</tr>
									</thead>
									<tbody>
										{userRechargeData && userRechargeData.map((recharge, index) => (
											<tr key={index}>
												<td className="py-2"><p className="text-white mb-0" style={{fontSize:"14px"}}>{index+1}</p></td>
												<td className="py-2"><p className="text-white mb-0" style={{fontSize:"14px"}}>{recharge.recharge_token}</p></td>
												<td className="py-2"><p className="text-white mb-0" style={{fontSize:"14px"}}>{parseFloat(recharge.recharge_amount).toFixed(2)}</p></td>
												<td className="py-2">
													{recharge.status == "Processing" ? (
														<button className="btn btn-warning" type="submit" data-toggle="modal" data-target="#rechargeModal" onClick={(e) => {
															setSelectedRechargeOrder(recharge);
															setAmount(recharge.recharge_amount);
														}}>
															<p className="mb-0"style={{fontSize:"14px"}}>转送</p>
														</button>
													):(
														<p className="profit-green mb-0" style={{fontSize:"14px"}}>完成</p>
													)}
												</td>
												<td className="py-2"><p className="text-white mb-0" style={{fontSize:"14px"}}>{Moment(recharge.createdAt).format('YYYY/MM/DD HH:mm')}H</p></td>
											</tr>
										))}
									</tbody>
								</table>
							):(
								<p className="text-gray mb-0">此账户没有充值记录。</p>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	);

};

export default Wallet;