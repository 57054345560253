// Dependencies
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import MiddleEllipsis from "react-middle-ellipsis";
import AOS from 'aos';
import 'aos/dist/aos.css';
// import Countdown, { zeroPad } from 'react-countdown';
// import Moment from 'moment';


import Promo1 from './assets/img/launch-promo.png';

import MainLogo from './assets/img/logo.png';
import CustomBtn from './assets/img/custom-btn.png';
import AIMMToken from './assets/img/aimm-token.png';
import USP1 from './assets/img/usp1.png';
import USP2 from './assets/img/usp2.png';
import USP3 from './assets/img/usp3.png';
import USP4 from './assets/img/usp4.png';
import AimmTokenStack from './assets/img/aimm-token-stack.png';

// Pages
import Wallet from "./pages/wallet";
import Trading from "./pages/trading";
import Invest from "./pages/invest";
import Account from "./pages/account";

// Bottom Menu
import MyAssetsIcon from './assets/img/assets-icon.png';
import TradeIcon from './assets/img/trade-icon.png';
import DepositIcon from './assets/img/deposit-icon.png';
import AccountIcon from './assets/img/account-icon.png';
import MyAssetsIconSelected from './assets/img/assets-icon-selected.png';
import TradeIconSelected from './assets/img/trade-icon-selected.png';
import DepositIconSelected from './assets/img/deposit-icon-selected.png';
import AccountIconSelected from './assets/img/account-icon-selected.png';

// hooks
import { useSignup } from './hooks/useSignup';


function App() {
  AOS.init();
  const queryParameters = new URLSearchParams(window.location.search)
  const referrerWallet = queryParameters.get("referral")

	const [bottomMenuSelected, setBottomMenuSelected] = useState("account");
  const [noticeboardClicked, setNoticeboardClicked] = useState(true);

  // Set state for blockchain data
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);

  const [aimmPrice, setAimmPrice] = useState();
	const fetchAimmFromDex = async () => {
		const response = await fetch('https://api.dexscreener.com/latest/dex/pairs/solana/HLWyH4kaeVimURK25aWShaaCas3CdjBndmusgjHVKVQq');
		const json = await response.json();
		if (response.ok) {
			// console.log(json.pair.priceUsd);
			setAimmPrice(json.pair.priceUsd);
		}
	}

  const getData = () => {
    if (blockchain.account !== "" && blockchain.tradeVisionAIDeposit !== null) {
      handleSignup();
    }
  };

  useEffect(() => {
    fetchAimmFromDex();
    getData();
    console.log("Referrer Wallet from URL:", referrerWallet);
  }, [blockchain.account]);

  const {walletSignup, error, isLoading} = useSignup();

  const handleSignup = async () => {
    if (referrerWallet == null || referrerWallet == '') {
      await walletSignup(blockchain.account, "0xa6ff14636c31fa4799d742185f723522c8f2ab48");
    } else {
      await walletSignup(blockchain.account, referrerWallet);
    }
  };

  return (
    <Router>

      <div className="modal fade" id="languageModal" tabindex="-1" data-keyboard="false" aria-labelledby="languageModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content invest-modal p-3">
						<div className="modal-body text-center px-3">
              <h5 className="text-white">语言</h5>
              <button className="btn custom-btn" onClick={(e) => {
                window.location.href = 'https://en.tradevisionai.com/';
              }}>
                <img className="w-100" src={CustomBtn}></img>
                <p className="custom-btn-text text-white mb-0"><strong>英文</strong></p>
              </button>
						</div>
					</div>
				</div>
			</div>


      <div className="container-fluid fluid-padding header-section py-3">
        <div className="row">
          <div className="col-7 col-sm-6 px-1">
            <NavLink to="/">
              <button className="btn p-0" onClick={(e) => {
              }}>
                <img className="header-logo" src={MainLogo}></img>
              </button>
            </NavLink>
          </div>
          {/* <div className="col-5 col-sm-6 text-right px-1">
            {blockchain.account === "" || blockchain.tradeVisionAIDeposit === null ? (
              <>
                <button className="btn header-connect-btn mt-2" data-toggle="modal" data-target="#languageModal" onClick={(e) => {
                }}>
                  <p className="text-white mb-0">语言</p>
                </button>
              </>
            ) : (
              <>
                <button className="btn header-connect-btn mt-2" onClick={(e) => {
                }}>
                  <MiddleEllipsis><span className="text-white mb-0" style={{fontSize:"11px"}}>{blockchain.account}</span></MiddleEllipsis>
                </button>
              </>
            )}
          </div> */}
        </div>
      </div>

      {!noticeboardClicked ? (
        <>
          <div className="container mt-3 pb-5">
            <div className="row">
              <div className="col-12">
                <h3 className="blue text-center mt-5">公告栏</h3>
                <div className="data-box mt-3">
                  <p className="blue text-center">AIMM系统更新公告：</p>
                  <p className="text-white text-left">
                    &#160;&#160;&#160;&#160;&#160;&#160;&#160;AIMM系统已经成功更新，系统的数据即将重新结算，将更新期间的收益结算到用户里。结算过程中给您带来不便敬请谅解，感谢大家一直以来对Aimm的支持和追随，相信AIMM的明天一定会更好
                  </p>
                  <p className="blue text-center mt-5">AIMM System Notice</p>
                  <p className="text-white text-justify">
                    &#160;&#160;&#160;&#160;&#160;&#160;The system has been upgraded successfully. However, the earnings generated during maintenance will be updated shortly. We apologize for any inconvenience caused during the update process. Thank you for your continued support and trust in Trade Vision AI. We believe that the future of AIMM will be even better!
                  </p>
                </div>
              </div>
              <div className="col-12 text-center">
                <button className="btn custom-btn mt-3" onClick={(e) => {
                  setNoticeboardClicked(true);
                  window.scrollTo(0, 0);
                }}>
                  <img className="w-100" src={CustomBtn}></img>
                  <h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>前进网页</strong></h5>
                </button>
              </div>
            </div>
          </div>

        </>
      ):(
        <>
          <Routes>
            <Route path="/" element={
              !blockchain.account ? (
                <>
                  <section className="hero-section">
                    <div className="container landing-form-page">
                      <div className="row">
                        <div className="col-12 text-center px-0">
                          <h3 className="hero-text text-white" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="400">
                            <strong>欢迎来到,&#160;&#160;&#160;&#160;&#160;<br></br>&#160;&#160;&#160;AI交易视界</strong>
                          </h3>
                            {blockchain.account === "" || blockchain.tradeVisionAIDeposit === null ? (
                              <>
                                {/* <h4 className="yellow mt-5">系统正在升级中</h4> */}
                                <button className="btn custom-btn mt-3" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="700" onClick={(e) => {
                                  e.preventDefault();
                                  dispatch(connect());
                                  getData();
                                }}>
                                  <img className="w-100" src={CustomBtn}></img>
                                  <h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>链接钱包</strong></h5>
                                </button>
                                <iframe className="responsive-iframe mt-5" src="//player.bilibili.com/player.html?isOutside=true&aid=113100799872834&bvid=BV11cpietEYc&cid=25785929706&p=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000"></iframe>
                              </>
                            ) : (
                              <>
                                <h5 className="text-white mt-3"><i className="profit-green fa-sharp fa-solid fa-circle-check mr-2"></i>成功连接钱包</h5>
                                <MiddleEllipsis><span className="text-white mb-0">{blockchain.account}</span></MiddleEllipsis>
                              </>
                            )}
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="intro-section">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-12 text-center">
                          <h3 className="text-white">AI交易视界项目介绍</h3>
                          <h5 className="blue">Trade Vision AI Project Introduction</h5>
                          <hr className="divider"></hr>
                          <p className="text-white text-justify mt-5" style={{fontSize:"16px"}} data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="600">
                          &#160;&#160;&#160;&#160;&#160;&#160;AI交易视界是首个基于AI链上自动交易平台，AIMM是该平台代币，AI交易视界利用先进的AI技术精确预测市场趋势，抓取聪明钱包的精准数据，更好的跟随成功交易者，复制他们的投资策略，通过AI交易视界的高频交易和套利来管理您的投资。同时，AI交易视界是一个 BSC / ETH / TON / Aptos等的多链Swap，利用先进的AI算法优化代币交换过程，同时可以减少滑点和交易成本，利用四大应用，推动平台代币AIMM的增长与价值。AIMM的总发行量为1,000,000,000枚，其中80%公平发射，面向社区用户，确保平台的公平性与透明度，20%基金会持有用于平台建设与持续发展并支持ai交易视界长期稳定运行。欢迎加入AI交易视界成为我们社区的一员，您不仅可以通过AI交易视界最大化您的收益，还可以通过AIMM代币长期稳定的增长来获取您最大的价值。
                          </p>
                          <div className="row mt-5">
                            <div className="col-sm-6 px-2" data-aos="zoom-out" data-aos-easing="ease-out-cubic" data-aos-duration="500" data-aos-delay="300">
                              <img className="w-100" src={USP1}></img>
                            </div>
                            <div className="col-sm-6 px-2" data-aos="zoom-out" data-aos-easing="ease-out-cubic" data-aos-duration="500" data-aos-delay="400">
                              <img className="w-100" src={USP2}></img>
                            </div>
                            <div className="col-sm-6 px-0" data-aos="zoom-out" data-aos-easing="ease-out-cubic" data-aos-duration="500" data-aos-delay="500">
                              <img className="w-100" src={USP3}></img>
                            </div>
                            <div className="col-sm-6 px-0" data-aos="zoom-out" data-aos-easing="ease-out-cubic" data-aos-duration="500" data-aos-delay="600">
                              <img className="w-100" src={USP4}></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="usp-section">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-12 graphic-1"></div>
                      </div>
                    </div>
                  </section>

                  <section className="black-bg">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 text-center" data-aos="zoom-out" data-aos-easing="ease-out-cubic" data-aos-duration="400">
                          <h2 className="blue">AIMM平台币</h2>
                          <img className="homepage-token mt-3" src={AimmTokenStack} alt="AIMM Token"></img>
                          <div className="token-details-container p-3 mt-3">
                            <p className="homepage-token-price text-white mt-3 mb-0">${aimmPrice}</p>
                            <button className="btn custom-btn mt-2" data-aos="fade-up" onClick={(e) => {
                              window.open("https://ave.ai/token/7n8Ye4vcEsNVkUREqbKwsQojypiHtGJShRcYs12DZXCD-solana", "_blank");
                            }}>
                              <img className="w-100" src={CustomBtn}></img>
                              <h5 className="custom-btn-text text-white mb-0" style={{fontSize:"18px"}}><strong>AVE</strong></h5>
                            </button>
                            <button className="btn custom-btn mt-2" data-aos="fade-up" onClick={(e) => {
                              window.open("https://raydium.io/swap/?inputMint=sol&outputMint=7n8Ye4vcEsNVkUREqbKwsQojypiHtGJShRcYs12DZXCD", "_blank");
                            }}>
                              <img className="w-100" src={CustomBtn}></img>
                              <h5 className="custom-btn-text text-white mb-0" style={{fontSize:"18px"}}><strong>SWAP</strong></h5>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  
                  <section className="token-section">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 text-center px-0">
                          <div className="token-video-padding">
                            <iframe className="frame-container" src="//player.bilibili.com/player.html?isOutside=true&aid=113100799870323&bvid=BV12cpietEF1&cid=25785991833&p=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="500"></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                
                </>
              ) : (
                <Navigate to="/account" />
              )
            }/>
            <Route path="/wallet" element={blockchain.account ? <Wallet/> : <Navigate to="/" />}/>
            <Route path="/trading" element={blockchain.account ? <Trading/> : <Navigate to="/" />}/>
            <Route path="/invest" element={blockchain.account ? <Invest/> : <Navigate to="/" />}/>
            <Route path="/account" element={blockchain.account ? <Account/> : <Navigate to="/" />}/>
          </Routes>

          {blockchain.account === "" || blockchain.tradeVisionAIDeposit === null ? (
          <></>
          ) : (
          <>
            {/* Bottom Menu */}
            <div className="container-fluid bottom-menu py-2">
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <NavLink to="/wallet">
                    <button className="btn px-1" onClick={(e) => {
                      setBottomMenuSelected("wallet");
                      window.scrollTo(0, 0);
                    }}>
                      <img className="bottom-menu-icon" src={bottomMenuSelected == "wallet" ? MyAssetsIconSelected : MyAssetsIcon}></img>
                      <p className="bottom-menu-text text-white mb-0">钱包</p>
                    </button>
                  </NavLink>
                  <NavLink to="/trading">
                    <button className="btn px-1" onClick={(e) => {
                      setBottomMenuSelected("trading");
                      window.scrollTo(0, 0);
                    }}>
                      <img className="bottom-menu-icon" src={bottomMenuSelected == "trading" ? TradeIconSelected : TradeIcon}></img>
                      <p className="bottom-menu-text text-white mb-0">交易</p>
                    </button>
                  </NavLink>
                  <NavLink to="/invest">
                    <button className="btn px-1" onClick={(e) => {
                      setBottomMenuSelected("invest");
                      window.scrollTo(0, 0);
                    }}>
                      <img className="bottom-menu-icon" src={bottomMenuSelected == "invest" ? DepositIconSelected : DepositIcon}></img>
                      <p className="bottom-menu-text text-white mb-0">托管</p>
                    </button>
                  </NavLink>
                  <NavLink to="/account">
                    <button className="btn px-1" onClick={(e) => {
                      setBottomMenuSelected("account");
                      window.scrollTo(0, 0);
                    }}>
                      <img className="bottom-menu-icon" src={bottomMenuSelected == "account" ? AccountIconSelected : AccountIcon}></img>
                      <p className="bottom-menu-text text-white mb-0">我的</p>
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </>
          )}
        </>
      )}
    </Router>
  );
}

export default App;