import { useState } from 'react';

export const useRecharge = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const createRecharge = async (curUserID, wallet, rechargeAmount) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('https://api.tradevisionai.com/recharge/create', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                user_id: curUserID,
                wallet: wallet,
                recharge_amount: rechargeAmount
            })
        })
        const json = await response.json();
        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
        }
        if (response.ok) {
            setIsLoading(false);
        }
    }

    const uploadTXN = async (rechargeID, submittedLink) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('https://api.tradevisionai.com/recharge/update/'+rechargeID, {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                txn_link: submittedLink,
            })
        })
        const json = await response.json();
        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
        }
        if (response.ok) {
            setIsLoading(false);
        }
    }

    return { createRecharge, uploadTXN, isLoading, error };
}