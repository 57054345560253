
export const CONFIG = {
  API_URL: 'https://api.tradevisionai.com',
  CONTRACT_ADDRESSES: {
    USDT: '0x55d398326f99059fF775485246999027B3197955',
    DEPOSIT: '0xd2E1236456B07B0baD066344659D161a171CCAE2',
  },
  COMMISSION_PERCENTAGES: [
    0.20, // Layer 0
    0.10, 0.10, 0.10, 0.10, // Layers 1 to 4
    0.05, 0.05, 0.05, 0.05, 0.05, // Layers 5 to 9
    0.03, 0.03, 0.03, 0.03, // Layers 10 to 13
    0.07  // Layer 14
  ],
  RANK_BONUS_PERCENTAGES: [
    0,
    0.05,
    0.1,
    0.2,
    0.3,
    0.4
  ],
  AIMM_CONTRACT_PAIR: 'https://api.dexscreener.com/latest/dex/pairs/solana/HLWyH4kaeVimURK25aWShaaCas3CdjBndmusgjHVKVQq',
};
